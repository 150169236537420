.cardsMessage {
  display: grid;
  grid-template-rows: 1fr;
  gap: 20px;
  border: 1px solid #dbdbdb;
  padding: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background: white;
  max-width: 100%;
}
@media (max-width: 665px) {
  .cardsMessage {
    max-width: 90%;
    margin: 0 !important;
  }
}
.cardsMessage .cardMessage .title {
  color: #7e00d3;
  font-weight: 500;
}
.cardsMessage .cardMessage .content {
  max-height: 200px;
  overflow: hidden;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #555555;
  font-weight: 400;
  padding: 0 17px;
  margin-bottom: 10px;
  font-weight: 400;
}
.cardsMessage .cardMessage a {
  color: #7e00d3;
  font-weight: 400;
  color: #fe8045;
}
.cardsMessage .ant-btn-primary {
  display: block;
  width: 200px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: unset;
  border-color: #7e00d3;
}
.cardsMessage .ant-btn-primary:hover {
  background: #7e00d3;
}
.cardsMessage .ant-btn-primary:hover span {
  color: #fff;
}
.cardsMessage .ant-btn-primary:hover span svg path {
  fill: #fff !important;
}
.cardsMessage .ant-btn-primary span {
  font-weight: 400;
}

.app__header.colored .app__header-menu .cardMessage a svg path {
  fill: #7e00d3 !important;
}/*# sourceMappingURL=Cards.css.map */