.app__container {
  width: 100%;
  margin: 0 auto;
  padding: 0 3%;
}
@media (max-width: 1280px) {
  .app__container {
    max-width: 1100px;
  }
}
@media (min-width: 1440px) {
  .app__container {
    max-width: 1280px;
  }
}/*# sourceMappingURL=AppContainer.css.map */