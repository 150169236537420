@import "../../../config/style/variables";
@import "../../../config/style/breackpoints";

.slick-dots li button:before {
    display: none !important;
}

.app__audioPlayer {
    &-container {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 10000;
        // background: transparent linear-gradient(100deg, #7E00D3 0%, #CB009D 100%) 0% 0% no-repeat padding-box;
        transition: bottom 0.2s;
        background: #333333;
        // height: 90px;
        padding: 0.5em 0;

        .ant-slider {
            position: absolute;
            top: -6px;
            left: 0;
            width: 100%;
            margin: 0;
            z-index: 5;

            .ant-slider-track {
                background: transparent linear-gradient(100deg, #7e00d3 0%, #cb009d 100%) 0% 0% no-repeat padding-box;
            }
        }

        .title__mobile {
            color: #a8a8a8;

            @include media(">=phone") {
                display: none;
            }
        }

        .music-player-container {
            display: grid;
            grid-template-columns: 1.5fr 1fr 1fr;
            height: 100%;

            @include media("<=phone") {
                grid-template-columns: 1fr 1.5fr;
            }

            .music-infos {
                color: #fff;

                .img-wrapper {
                    height: 70px;
                    width: 100px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .text-infos {
                    padding-left: 10px;

                    h4,
                    div.ant-typography,
                    .ant-typography p {
                        color: #fff;
                        font-size: 16px;
                        text-transform: uppercase;
                        margin-bottom: 8px;
                        margin-top: 3px;

                        @include media("<=phone") {
                            font-size: 12px;
                        }
                    }

                    p {
                        color: #a8a8a8;

                        @include media("<=phone") {
                            font-size: 10px;
                        }
                    }
                }
            }

            .__custom_btn {
                @include media("<=phone") {
                    padding: 4px 3.5px !important;
                }
            }

            .__custom_position {
                @include media("<=phone") {
                    position: relative;
                    top: -2px;
                }
            }

            .music-playing-items {
                .ant-btn-text {
                    height: unset;

                    &:first-child,
                    &:nth-child(3) {
                        svg {
                            fill: #5f5f5f;
                            width: 30px;
                            height: 30px;
                        }
                    }

                    &:not(:last-child, :first-child) {
                        svg {
                            fill: #fff;
                            width: 40px;
                            height: 40px;
                            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

                            &:hover {
                                transform: scale(1.1);
                            }

                            @include media("<=phone") {
                                width: 25px;
                                height: 25px;
                            }
                        }
                    }
                }

                svg {
                }
            }

            .music-features {
                ._is_mobile_dsp__none {
                    @include media("<=phone") {
                        display: none;
                    }
                }

                display: flex;

                svg {
                    width: 20px;
                    height: 20px;
                    fill: #a8a8a8;

                    path {
                        fill: #a8a8a8;
                    }

                    &.active {
                        path {
                            fill: #fff;
                        }
                    }
                }

                span {
                    font-size: 13px;
                    margin: 0 2px;
                }

                .music-time {
                    span {
                        color: #a8a8a8;
                    }
                }

                .music-volume {
                }
            }
        }

        .rhap_container {
            background: transparent;
            box-shadow: unset;

            svg {
                width: 25px;
                height: 25px;
                fill: #fff;

                path {
                    fill: #fff;
                }
            }

            .rhap_progress-filled,
            .rhap_progress-indicator,
            .rhap_volume-indicator {
                background: #fe8045;
            }

            .rhap_time {
                color: #fff;
            }
        }

        .rhap_stacked .rhap_controls-section {
            margin-top: 0;
        }
    }

    &-wrapper {
    }
}

.custom-bg {
    .ant-slider-track {
        background: transparent linear-gradient(100deg, #7e00d3 0%, #cb009d 100%) 0% 0% no-repeat padding-box;
    }
}
