.chatbot__text {
  overflow: visible;
  padding: 1.6rem 2.4rem;
  max-width: 100%;
  max-width: 80%;
  display: inline-block;
  font-weight: 400;
  font-size: 1.5rem;
  min-width: 110px !important;
}
@media (max-width: 665px) {
  .chatbot__text {
    max-width: 90%;
  }
}
.chatbot__text-container {
  display: flex;
}
.chatbot__text.left {
  border-radius: 1.2rem 1.2rem 1.2rem 0.125rem;
  background-color: rgb(255, 255, 255);
  color: rgb(47, 50, 74);
}
.chatbot__text.right {
  margin-left: auto;
  text-align: right;
  color: #fff;
  background: transparent linear-gradient(200deg, #8c00cb 0%, #3de0fe 100%) 0% 0% no-repeat padding-box;
  border-radius: 1.2rem 1.2rem 0.125rem 1.2rem;
  display: none;
}/*# sourceMappingURL=Text.css.map */