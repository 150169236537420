.chatbot__btn {
  border: none;
  background: transparent linear-gradient(244deg, #8C00CB 0%, #3DE0FE 100%) 0% 0% no-repeat padding-box;
  color: #fff;
  padding: 16px;
  border-radius: 10px;
  cursor: pointer;
  transition: box-shadow, opacity 0.2s;
  outline: none;
  position: relative;
  overflow: hidden;
}
.chatbot__btn.active::before {
  opacity: 1;
}
.chatbot__btn.active:hover::before {
  opacity: 1;
}
.chatbot__btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0;
  transition: opacity 0.8s;
  z-index: -1;
}
.chatbot__btn:hover::before {
  opacity: 1;
}
.chatbot__btn:focus {
  outline: none;
}
.chatbot__btn:hover {
  opacity: 0.9;
  box-shadow: rgba(0, 0, 0, 0.29) 0px 6px 21px -11px, rgba(47, 49, 72, 0.3) 0px 13px 17px -16px;
}/*# sourceMappingURL=Button.css.map */