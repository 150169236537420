@import url("https://fonts.googleapis.com/css?family=Rubik:700,500,300,400,400italic,300italic");
@font-face {
  font-family: "biko";
  src: url("./assets/fonts/Biko_Regular.otf");
}
@font-face {
  font-family: "biko-bold";
  src: url("./assets/fonts/Biko_Bold.otf");
}
html {
  scroll-behavior: smooth !important;
  --antd-wave-shadow-color: unset !important;
}

*,
*::after,
*::before,
p {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Rubik", Arial, serif !important;
  font-weight: 500;
  font-size: 1.4rem;
}

.activeMenuV3 {
  visibility: visible !important;
  opacity: 1 !important;
  transform: translate(-50%, 0) !important;
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0s linear 0s !important;
}

*:focus {
  outline: none;
}

ol {
  list-style-type: disc;
}

ol li {
  list-style-type: decimal !important;
  padding-left: 0;
  margin-left: 1.5rem;
}

.loading_server {
  animation: 1s ease 0s normal forwards 1 fadein;
}

@keyframes fadein {
  0% {
    left: 0;
    opacity: 1;
  }
  66% {
    opacity: 1;
    left: 0;
  }
  100% {
    opacity: 0;
    left: -10000000%;
  }
}
.app__home section {
  width: 100%;
}

.hover-pointer:hover {
  cursor: pointer;
}

.ml-auto {
  margin-left: auto;
}

.flex-wrap {
  flex-wrap: wrap;
}

.outline-btn-link {
  display: flex;
  padding: 2px 15px;
  border: 2px solid currentColor;
  border-radius: 100px;
  transition: all 0.3s;
  align-items: center;
}
.outline-btn-link.violet {
  color: #7e00d3;
}
.outline-btn-link.violet:hover {
  background-color: #7e00d3;
  color: #fff;
  border-color: #7e00d3;
}
.outline-btn-link.orange {
  color: #fe8045;
}
.outline-btn-link.orange:hover {
  background-color: #fe8045;
  color: #fff;
  border-color: #fe8045;
}
.outline-btn-link.gray {
  color: #aaaaaa;
}
.outline-btn-link.gray:hover {
  background-color: #7e00d3;
  color: #fff;
  border-color: #7e00d3;
}

@media (max-width: 665px) {
  .hide-on-mobile {
    display: none !important;
  }
}

@media screen and (max-width: 1150px) {
  .wp-block-table,
  .wp-block-columns {
    max-width: 800px;
    overflow: scroll;
  }
}
.popover-link a {
  color: #333;
  font-weight: 400;
  margin-bottom: 5px;
}
.popover-link a:hover, .popover-link a.active, .popover-link a:focus {
  color: #7e00d3;
}
.popover-link .ant-btn-link {
  padding: 0 !important;
  display: flex;
  justify-content: start;
}
.popover-link .ant-btn-link:hover span {
  color: #7e00d3;
}
.popover-link .ant-btn-link span {
  color: #333;
  font-weight: 400;
}

.section-loading {
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-gray {
  background-color: #f5f5f5;
}

.avatar-container {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.avatar-container.onWhite {
  background-color: rgba(126, 0, 211, 0.2);
}
.avatar-container.onColor {
  background-color: rgba(255, 255, 255, 0.4);
}

.chatbot-animation-fixed {
  position: fixed;
  bottom: 40px;
  right: 50px;
  z-index: 100;
  width: 100px;
  cursor: pointer;
  transition: transform 0.2s, bottom 0.4s;
}
.chatbot-animation-fixed.withAudioPlayer {
  bottom: 100px;
}
@media (max-width: 665px) {
  .chatbot-animation-fixed {
    display: none;
  }
}
.chatbot-animation-fixed:hover {
  transform: scale(1.1);
}

@media (max-width: 1280px) {
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
  }
}
@media (min-width: 1440px) {
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
  }
}
.bg-white {
  background: white;
}

.bg-violetGradient {
  background: transparent linear-gradient(90deg, #d50392 0%, #8700d0 100%) 0% 0% no-repeat padding-box;
}

blockquote {
  background-color: #f9f0ff;
  padding: 15px;
  border-radius: 15px;
}
blockquote p {
  margin-bottom: 0 !important;
}

.bg-light {
  background-color: #f5f5f5;
}

.m-0 {
  margin: 0;
}

.text-gray-hover-orange {
  color: #707070;
  transition: all 0.2s;
}
.text-gray-hover-orange:hover {
  color: #fe8045 !important;
}

.h-100 {
  height: 100%;
}

.text-voilet {
  color: #7e00d3;
}

.text-capitalize {
  text-transform: capitalize;
}

.hide-on-desktop {
  display: none;
}
@media (max-width: 665px) {
  .hide-on-desktop {
    display: block;
  }
}

@media (min-width: 1440px) {
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
  }
}
.text-orange {
  color: #fe8045;
}

/* html{
  scroll-behavior: smooth;
}
 */
body,
html {
  font-size: 62.5% !important;
  overflow-x: hidden !important;
}
@media (min-width: 1280px) {
  body,
  html {
    font-size: 60% !important;
  }
}
@media (min-width: 1440px) {
  body,
  html {
    font-size: 62.5% !important;
  }
}

.text-white {
  color: white;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-bold {
  font-weight: 700 !important;
  font-family: "biko-bold", Arial, serif;
}

.text-center {
  text-align: center;
}

.d-flex {
  display: flex !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: flex-end;
}

.flex-direction-column {
  flex-direction: column;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.align-items-end {
  align-items: flex-end;
}

.flex-column {
  flex-direction: column;
}

.align-items-center {
  align-items: center !important;
}

.grid-autofit-5 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

a {
  text-decoration: none !important;
}

.orange-block-title {
  font-size: 3.5rem !important;
  font-weight: 500;
  font-family: "biko-bold", Arial, serif;
}
@media (max-width: 665px) {
  .orange-block-title {
    font-size: 2rem !important;
  }
}

.mb-block-title {
  margin-bottom: 4rem;
}

.block-btn.app__btn.ant-btn {
  padding: 1.3rem 3rem;
  border-radius: 4px;
  min-width: 250px;
}
@media (max-width: 665px) {
  .block-btn.app__btn.ant-btn {
    width: 80%;
  }
}

.mx-auto {
  margin: 0 auto !important;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mb-auto {
  margin-bottom: auto;
}

.grow-on-hover {
  transition: transform 0.2s !important;
}

.grow-on-hover:hover {
  transform: scale(1.05);
}

.small-avatar {
  width: 20px !important;
  height: 20px !important;
}

.screen-form__container {
  display: flex;
  position: relative;
  align-items: center;
}

.screen-form__container img {
  max-width: 250px;
}

.screen-form__field {
  padding: 10px;
  border: 1px solid rgb(140, 0, 203);
  border-radius: 5px;
  min-width: 300px;
  outline: none;
  color: #7d868c;
  font-size: 1.4rem;
}

.screen-form__field:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(140, 0, 203, 0.2);
}

.screen-form__field::-moz-placeholder {
  color: #bcbcbc;
  font-style: italic;
}

.screen-form__field::placeholder {
  color: #bcbcbc;
  font-style: italic;
}

.MuiCircularProgress-circle {
  stroke: #fe8045 !important;
}

.overflow-hidden {
  overflow: hidden;
}

.dot {
  width: 10px;
  height: 10px !important;
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 50%;
}

.dot-container {
  width: 30px;
  height: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50% !important;
}

.slick-active .dot-container {
  border: 1px solid rgba(255, 255, 255, 0.7);
}

.slick-active .dot {
  background-color: white;
}

.grid-col-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.grid-col-1 {
  display: grid;
  grid-template-columns: 1fr;
}

.card-skeleton {
  width: 100%;
  height: 100%;
  height: 380px;
  border-radius: 8px;
  overflow: hidden;
}

.card-skeleton span {
  width: 100% !important;
  height: 100% !important;
}

.ant-tabs {
  overflow: unset !important;
}

.cursor-pointer {
  cursor: pointer;
}

.w-100 {
  width: 100% !important;
}

.font-size-normal {
  font-size: 1.6rem;
}

.font-size-small {
  font-size: 1.4rem;
}

.text-hover-white:hover {
  color: #fff;
}

.position-relative {
  position: relative;
}

.mx-1 {
  margin: 1rem 0;
}

.mt-1 {
  margin-top: 1rem;
}

.mb-1P5 {
  margin-bottom: 1.5rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.text-violet {
  color: #7e00d3;
}

.small-border-rounded {
  border-radius: 5px;
}

.text-gray {
  color: #666666;
}

.light-weight {
  font-weight: 400;
}

.page-title {
  font-size: 2.5rem !important;
}

.align-items-start {
  align-items: flex-start;
}

@media (max-width: 665px) {
  .flex-mobile-column {
    flex-direction: column;
  }
}

li {
  list-style-type: none;
}

.d-none {
  display: none;
}

table,
th,
td {
  border: 1px solid #eaeaea;
}

table {
  width: 100%;
  table-layout: auto;
}

tbody {
  width: 100%;
  table-layout: auto;
}

th,
td {
  padding: 15px;
  color: #7d868c;
  word-break: break-word;
  min-width: 180px;
}

strong {
  color: #7d868c;
  font-size: 1.5rem;
}

figure td {
  font-weight: 400;
  color: #707070;
}

figure td > strong {
  font-weight: 500;
}

.slick-dots li button:before {
  font-size: 12px;
  color: white;
}

.slick-active .dot-container {
  border: 1px solid rgba(255, 255, 255, 0.7);
}
.slick-active .dot {
  background-color: white;
}

.custom-dots-style {
  bottom: 30px;
  display: flex !important;
  justify-content: flex-end;
  padding-right: 5%;
  z-index: 100;
  max-width: 200px;
  right: 0;
}
.custom-dots-style.rtl {
  width: 120px;
  left: 20px;
}

@media (max-width: 665px) {
  figure {
    overflow-x: scroll;
    width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 665px) {
  figure table {
    min-width: 900px;
    width: 100%;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-2-25 15:13:31
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */
@keyframes fade-in-bottom {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.mediatheque-screen-block__cards {
  display: grid;
  grid-template-columns: 55% 1fr;
  grid-template-rows: 15rem 15rem 15rem;
  gap: 2rem;
}
.mediatheque-screen-block__cards .media-card__container:not(:first-child) .media-card__head {
  width: 50% !important;
}
.mediatheque-screen-block__cards .ant-skeleton-element {
  width: 100%;
  height: 100%;
}
.mediatheque-screen-block__cards .ant-skeleton-element span {
  width: 100%;
  height: 100%;
}
.mediatheque-screen-block__cards .ant-skeleton-element:first-child {
  grid-row-end: span 3;
}
@media (max-width: 665px) {
  .mediatheque-screen-block__cards .ant-skeleton-element:first-child {
    grid-row-end: unset;
  }
}
@media (max-width: 665px) {
  .mediatheque-screen-block__cards {
    grid-template-columns: 1fr;
    grid-template-rows: 15rem 15rem 15rem 15rem;
  }
}
.mediatheque-screen-block__cards > * {
  margin: 0 !important;
}
.mediatheque-screen-block__cards > *:not(:first-child) {
  display: flex;
}
.mediatheque-screen-block__cards > *:not(:first-child) .media-card__head {
  height: auto;
  width: 50%;
}
.mediatheque-screen-block__cards > *:not(:first-child) .media-card__body {
  padding: 3% 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.mediatheque-screen-block__cards > *:not(:first-child) .media-card__title .text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}
.mediatheque-screen-block__cards > article:first-child {
  grid-row-end: span 3;
}
.mediatheque-screen-block__cards > article:first-child .media-card__head {
  height: 70%;
}
.mediatheque-screen-block__cards > article:first-child .media-card__body {
  padding: 1% 3%;
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.app__tab .ant-tabs-top > .ant-tabs-nav::before,
.app__tab .ant-tabs-bottom > .ant-tabs-nav::before,
.app__tab .ant-tabs-top > div > .ant-tabs-nav::before,
.app__tab .ant-tabs-bottom > div > .ant-tabs-nav::before {
  border: none;
}
.app__tab .ant-tabs-tab:not(:first-child) {
  width: 13rem;
  color: #7d868c;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 500;
}
.app__tab .ant-tabs-nav {
  height: 4rem;
}
@media (max-width: 665px) {
  .app__tab .ant-tabs-nav {
    margin-bottom: 3rem;
    margin-top: 2rem;
  }
}
.app__tab .ant-tabs-tab.ant-tabs-tab-active {
  background: transparent linear-gradient(256deg, #d50392 0%, #8700d0 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
}
.app__tab .ant-tabs-tab.ant-tabs-tab-active > div {
  color: white;
  font-size: 1.6rem;
  font-weight: 400;
}
.app__tab .ant-tabs {
  overflow: unset !important;
}
.app__tab .ant-tabs-tab:hover {
  color: unset;
}
@media (max-width: 665px) {
  .app__tab .ant-tabs-tab.ant-tabs-tab-disabled {
    display: none;
  }
}
@media (max-width: 665px) {
  .app__tab .ant-tabs-tab {
    margin-right: 0;
  }
}
@media (max-width: 665px) {
  .app__tab .ant-tabs-nav-list {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
.app__tab .ant-tabs-ink-bar {
  background: transparent;
}

*,
*::after,
*::before,
p {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Rubik", Arial, serif !important;
  font-weight: 500;
  font-size: 1.4rem;
}

.ant-btn {
  gap: 5px;
}

.home-mediacard-body {
  min-height: 180px;
  display: flex;
  flex-direction: column;
}

.home-mediacard-body .text {
  margin-top: auto;
}

.mt-auto {
  margin-top: auto !important;
}

.ant-back-top {
  right: 10px;
  border-radius: unset;
  z-index: 10;
}

@media only screen and (max-width: 500px) {
  .ant-back-top {
    bottom: 0.8em;
  }
}
.ant-back-top-content {
  background-color: rgba(244, 117, 47, 0.6) !important;
  border-radius: unset;
}

.ant-back-top-content:hover {
  background-color: #fe8044 !important;
}

.ant-back-top-rtl {
  right: 0;
  left: 10px;
  bottom: 10px;
}/*# sourceMappingURL=App.css.map */