.chatbot__btn {
    border: none;
    // background: transparent linear-gradient(30deg, #3DE0FE 0%, #8C00CB 70%) 0% 0% no-repeat padding-box;
    // background: #617AE7;
    // background: transparent linear-gradient(299deg, #23b5cf 0%, #8C00CB 70%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(244deg, #8C00CB 0%, #3DE0FE 100%) 0% 0% no-repeat padding-box;
    color: #fff;
    padding: 16px;
    // width: 100%;
    border-radius: 10px;
    cursor: pointer;
    transition: box-shadow, opacity 0.2s;
    outline: none;
    position: relative;
    overflow: hidden;

    &.active::before {
        opacity: 1;
    }

    &.active:hover::before {
        opacity: 1;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        opacity: 0;
        transition: opacity 0.8s;
        z-index: -1;
        // background: linear-gradient(241.43deg, #fe772e 28.89%, #FE8045 74.4%);
        // background: transparent linear-gradient(230deg, #3DE0FE 0%, #8C00CB 70%) 0% 0% no-repeat padding-box;

    }

    &:hover::before {
        opacity: 1;
    }


    &:focus {
        outline: none;
    }

    &:hover {
        opacity: 0.9;
        box-shadow: rgba(0, 0, 0, 0.29) 0px 6px 21px -11px, rgba(47, 49, 72, 0.3) 0px 13px 17px -16px;
    }
}