@import '../../../config/style/breackpoints';

.app__container{
    width: 100%;
    
    margin: 0 auto;
    padding: 0 3%;

    // @include media("<=phone") {
    //     overflow-x: hidden;
    // }

    @include media("<=desktop") {
        max-width: 1100px;
    }

    @include media(">=LGdesktop") {
        max-width: 1280px;
    }
    
}





