.app__backdrop {
    position: fixed;
    width: 100vw;
    height: 130vh;
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    // background: transparent linear-gradient(110deg, #8C00CB 0%, #FE3D4A 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(110deg, #8C00CB 0%, #3DE0FE 100%) 0% 0% no-repeat padding-box;
    ;

    top: 0;
    left: 0;
    overflow: hidden;

    img {
        margin-bottom: 50px;
    }

    .circle-pulse {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 0 0 rgba(255, 255, 255, 0.4);
        animation: pulse 2s infinite;
    }
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    }

    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.8);
    }

    70% {
        -moz-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}