.chatbot__modalcard .ant-modal-footer .ant-btn.ant-btn-primary {
  background-color: #7E00D3;
  border-color: #7E00D3;
}
.chatbot__modalcard .content__header {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  padding: 20px;
  background-color: #f7f7f7;
  margin-bottom: 20px;
}
.chatbot__modalcard .content__header .sidebar-block {
  padding-left: 20%;
}
.chatbot__modalcard .content__header .sidebar-block__head img {
  width: 35px;
  height: 35px;
}
.chatbot__modalcard .content__header .sidebar-block__head .title {
  text-transform: uppercase;
  color: #FE8045;
  font-size: 1.3rem;
  font-weight: 500;
  margin-top: 8px;
}
.chatbot__modalcard .content__header .sidebar-block__content p {
  font-size: 1.4rem;
  font-weight: 400;
  color: #555;
}
.chatbot__modalcard .content * {
  font-weight: 400;
}
.chatbot__modalcard .content h3 {
  color: #FE8045;
  margin-bottom: 0;
  margin-top: 20px;
  font-weight: 500;
}
.chatbot__modalcard .content h3 strong {
  color: #FE8045;
  font-weight: 500;
}/*# sourceMappingURL=Modalcard.css.map */