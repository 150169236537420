body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.svgHeader:hover {
  fill: red !important;
}

/* _____ */

@media screen and (max-width : 500px) {
  .w_100 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 !important;
  }
}


/* ______ */

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}

/* .chatbot__text-container p {
  min-width: 110px !important;
} */

.sub__para__footer {
  margin-top: 1em;
  color: #E3E3E3;
  font-size: 1.2rem !important;
  line-height: 15px !important;
}

.ant-select-dropdown {
  z-index: 99999 !important;
}