.slick-dots li button:before {
  display: none !important;
}

.app__audioPlayer-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  transition: bottom 0.2s;
  background: #333333;
  padding: 0.5em 0;
}
.app__audioPlayer-container .ant-slider {
  position: absolute;
  top: -6px;
  left: 0;
  width: 100%;
  margin: 0;
  z-index: 5;
}
.app__audioPlayer-container .ant-slider .ant-slider-track {
  background: transparent linear-gradient(100deg, #7e00d3 0%, #cb009d 100%) 0% 0% no-repeat padding-box;
}
.app__audioPlayer-container .title__mobile {
  color: #a8a8a8;
}
@media (min-width: 665px) {
  .app__audioPlayer-container .title__mobile {
    display: none;
  }
}
.app__audioPlayer-container .music-player-container {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  height: 100%;
}
@media (max-width: 665px) {
  .app__audioPlayer-container .music-player-container {
    grid-template-columns: 1fr 1.5fr;
  }
}
.app__audioPlayer-container .music-player-container .music-infos {
  color: #fff;
}
.app__audioPlayer-container .music-player-container .music-infos .img-wrapper {
  height: 70px;
  width: 100px;
}
.app__audioPlayer-container .music-player-container .music-infos .img-wrapper img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.app__audioPlayer-container .music-player-container .music-infos .text-infos {
  padding-left: 10px;
}
.app__audioPlayer-container .music-player-container .music-infos .text-infos h4,
.app__audioPlayer-container .music-player-container .music-infos .text-infos div.ant-typography,
.app__audioPlayer-container .music-player-container .music-infos .text-infos .ant-typography p {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 8px;
  margin-top: 3px;
}
@media (max-width: 665px) {
  .app__audioPlayer-container .music-player-container .music-infos .text-infos h4,
  .app__audioPlayer-container .music-player-container .music-infos .text-infos div.ant-typography,
  .app__audioPlayer-container .music-player-container .music-infos .text-infos .ant-typography p {
    font-size: 12px;
  }
}
.app__audioPlayer-container .music-player-container .music-infos .text-infos p {
  color: #a8a8a8;
}
@media (max-width: 665px) {
  .app__audioPlayer-container .music-player-container .music-infos .text-infos p {
    font-size: 10px;
  }
}
@media (max-width: 665px) {
  .app__audioPlayer-container .music-player-container .__custom_btn {
    padding: 4px 3.5px !important;
  }
}
@media (max-width: 665px) {
  .app__audioPlayer-container .music-player-container .__custom_position {
    position: relative;
    top: -2px;
  }
}
.app__audioPlayer-container .music-player-container .music-playing-items .ant-btn-text {
  height: unset;
}
.app__audioPlayer-container .music-player-container .music-playing-items .ant-btn-text:first-child svg, .app__audioPlayer-container .music-player-container .music-playing-items .ant-btn-text:nth-child(3) svg {
  fill: #5f5f5f;
  width: 30px;
  height: 30px;
}
.app__audioPlayer-container .music-player-container .music-playing-items .ant-btn-text:not(:last-child, :first-child) svg {
  fill: #fff;
  width: 40px;
  height: 40px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.app__audioPlayer-container .music-player-container .music-playing-items .ant-btn-text:not(:last-child, :first-child) svg:hover {
  transform: scale(1.1);
}
@media (max-width: 665px) {
  .app__audioPlayer-container .music-player-container .music-playing-items .ant-btn-text:not(:last-child, :first-child) svg {
    width: 25px;
    height: 25px;
  }
}
.app__audioPlayer-container .music-player-container .music-features {
  display: flex;
}
@media (max-width: 665px) {
  .app__audioPlayer-container .music-player-container .music-features ._is_mobile_dsp__none {
    display: none;
  }
}
.app__audioPlayer-container .music-player-container .music-features svg {
  width: 20px;
  height: 20px;
  fill: #a8a8a8;
}
.app__audioPlayer-container .music-player-container .music-features svg path {
  fill: #a8a8a8;
}
.app__audioPlayer-container .music-player-container .music-features svg.active path {
  fill: #fff;
}
.app__audioPlayer-container .music-player-container .music-features span {
  font-size: 13px;
  margin: 0 2px;
}
.app__audioPlayer-container .music-player-container .music-features .music-time span {
  color: #a8a8a8;
}
.app__audioPlayer-container .rhap_container {
  background: transparent;
  box-shadow: unset;
}
.app__audioPlayer-container .rhap_container svg {
  width: 25px;
  height: 25px;
  fill: #fff;
}
.app__audioPlayer-container .rhap_container svg path {
  fill: #fff;
}
.app__audioPlayer-container .rhap_container .rhap_progress-filled,
.app__audioPlayer-container .rhap_container .rhap_progress-indicator,
.app__audioPlayer-container .rhap_container .rhap_volume-indicator {
  background: #fe8045;
}
.app__audioPlayer-container .rhap_container .rhap_time {
  color: #fff;
}
.app__audioPlayer-container .rhap_stacked .rhap_controls-section {
  margin-top: 0;
}
.custom-bg .ant-slider-track {
  background: transparent linear-gradient(100deg, #7e00d3 0%, #cb009d 100%) 0% 0% no-repeat padding-box;
}/*# sourceMappingURL=AppAudioPlayer.css.map */