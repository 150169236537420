.chatbot__modal {
  padding: 1.6rem 2.4rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  max-width: 80%;
}
@media (max-width: 665px) {
  .chatbot__modal {
    max-width: 100%;
  }
}
.chatbot__modal .ant-btn-primary {
  display: block;
  min-width: 200px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: unset;
  border-color: #7E00D3;
}
@media (max-width: 665px) {
  .chatbot__modal .ant-btn-primary {
    min-width: 150px !important;
  }
}
.chatbot__modal .ant-btn-primary:hover {
  background: #7E00D3;
}
.chatbot__modal .ant-btn-primary:hover span {
  color: #fff;
}
.chatbot__modal .ant-btn-primary:hover span svg path {
  fill: #fff !important;
}
.chatbot__modal .ant-btn-primary span {
  font-weight: 400;
}
.chatbot__modal .program-card {
  margin-bottom: 20px;
}
.chatbot__modal .program-card .content {
  max-height: 45px;
  overflow: hidden;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #555555;
  font-weight: 400;
  margin-bottom: 10px;
}
.chatbot__modal .program-card .ant-btn-primary {
  margin: 0 !important;
}/*# sourceMappingURL=Modal.css.map */