.chatbot__modalcard{

    .ant-modal-footer{

        .ant-btn.ant-btn-primary{
            background-color: #7E00D3;
            border-color : #7E00D3;
        }
    }

    .title{

    }

    .content__header{
        display: grid;
        grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
        padding: 20px;
        background-color: #f7f7f7;
        margin-bottom: 20px;
        // justify-items: center;

        .sidebar-block{
           padding-left: 20%;

           &__head{

                img{
                    width: 35px;
                    height: 35px;
                }

                .title{
                    text-transform: uppercase;
                    color: #FE8045;
                    font-size: 1.3rem;
                    font-weight: 500;
                    margin-top: 8px;
                }
            }

            &__content{

                p{
                    font-size: 1.4rem;
                    font-weight: 400;
                    color: #555;
                }
            }
        }
    }

    .content{
        
        *{
            font-weight: 400;
        }

        h3{
            color: #FE8045;
            margin-bottom:0;
            margin-top: 20px;
            font-weight: 500;
            
            strong{
                color: #FE8045;
                font-weight: 500;
            }
        }
    }
}