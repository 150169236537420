@import '../../../../../config/style/breackpoints';


.chatbot__message{
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    @include media("<=phone") {
        flex-direction: column-reverse;
        align-items: flex-start;
        margin-bottom: 10px;
    }

    img{
        @include media("<=phone") {
            display: none;
        }
    }

    &.reverse{
        flex-direction: row-reverse;

        @include media("<=phone") {
            flex-direction: column-reverse;
            align-items: flex-end;
        }

        .content{
            @include media("<=phone") {
                width: 100%;
            }
        }
    }

    > *:first-child{
        width: 60px;

        span{
            display: block;
            margin-top: 5px;
            font-size: 10px;
            font-weight: 300;
        }
    }

    > *:last-child{
        flex: 1;
    }

    @include media("<=phone") {
        max-width: 100%;
    }
}

