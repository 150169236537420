@import '../../../../../config/style/breackpoints';

.chatbot__modal{
    padding : 1.6rem 2.4rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    max-width: 80%;

    @include media("<=phone") {
        max-width: 100%;
    }




    .ant-btn-primary{
        display: block;
        min-width: 200px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        background: unset;
        border-color: #7E00D3;

        @include media("<=phone") {
            min-width: 150px !important;
        }

        &:hover{
            background: #7E00D3;

            span{
                color: #fff;

                svg{
                    
                    path{
                        fill: #fff !important;
                    }
                }
            }
        }
        
        span{
            font-weight: 400;
        }
    }

    .program-card{

        margin-bottom: 20px;

        .title{

        }

        .content{
            max-height: 45px;
            overflow: hidden;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; 
            -webkit-box-orient: vertical;
            color: #555555;
            font-weight: 400;
            // padding: 0 17px;
            margin-bottom: 10px;
        }

        .ant-btn-primary{
            margin: 0 !important;
        }
    }
}

