.app__backdrop {
  position: fixed;
  width: 100vw;
  height: 130vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: transparent linear-gradient(110deg, #8C00CB 0%, #3DE0FE 100%) 0% 0% no-repeat padding-box;
  top: 0;
  left: 0;
  overflow: hidden;
}
.app__backdrop img {
  margin-bottom: 50px;
}
.app__backdrop .circle-pulse {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 0 rgba(255, 255, 255, 0.4);
  animation: pulse 2s infinite;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.8);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}/*# sourceMappingURL=BackDropLoader.css.map */