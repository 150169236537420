.chatbot__message {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
@media (max-width: 665px) {
  .chatbot__message {
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-bottom: 10px;
  }
}
@media (max-width: 665px) {
  .chatbot__message img {
    display: none;
  }
}
.chatbot__message.reverse {
  flex-direction: row-reverse;
}
@media (max-width: 665px) {
  .chatbot__message.reverse {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
}
@media (max-width: 665px) {
  .chatbot__message.reverse .content {
    width: 100%;
  }
}
.chatbot__message > *:first-child {
  width: 60px;
}
.chatbot__message > *:first-child span {
  display: block;
  margin-top: 5px;
  font-size: 10px;
  font-weight: 300;
}
.chatbot__message > *:last-child {
  flex: 1;
}
@media (max-width: 665px) {
  .chatbot__message {
    max-width: 100%;
  }
}/*# sourceMappingURL=Message.css.map */