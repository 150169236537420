.chatbot__tabs {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 800px !important;
    overflow-x: hidden !important;
    .ant-tabs-nav {
        margin-bottom: 0;
    }

    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        overflow-x: hidden !important;

        &.ant-tabs-tab-active {
            background: #7e00d3;
        }

        &:not(.ant-tabs-tab-active) {
            transition: color 0.2s !important;

            &:hover {
                background: #fe8045;
                color: #fff;
            }
        }
    }

    .cardsMessage {
        border-top: 0;
        border-top-left-radius: 0;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: white !important;
    }
}
