.rtl {
    direction: rtl;
}

/* the slides */
.section-toolbox .slick-list {
    margin: 0 -5px;
}
.section-toolbox .slick-slide > div {
    padding: 0 5px;
}
